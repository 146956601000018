$vp-tablet: 720px;
$vp-desktop: 1024px;
$vp-large: 1280px;
$vp-desktopHD: 1440px;

$vp-container: $vp-desktopHD;
$vp-container-small: 1100px;

/* Colors */

$vp-color-primary: #1b2350;
$vp-color-secondary-alt: #0DD0D7;
$vp-color-secondary: #0D4EA3;
$vp-color-dark: #333;
$vp-color-tertiary: #02a7e1;
$vp-color-white: #fff;
$vp-color-grey: #F1F5F6;
$vp-color-grey-dark: #C7D6E2;


/* Mobile */
:root {
    /* Font Size */
    --vp-font-size-base: calc(calc(16 / 16) * 1rem);
    --vp-font-size-level-1: calc(calc(24 / 16) * 1rem);
    --vp-font-size-level-2: calc(calc(22 / 16) * 1rem);
  
    /* Spacing */
    --spacing: 8px;
    --vp-content-spacing: calc(var(--spacing) * 3);
    --vp-content-spacing-V: calc(var(--spacing) * 6);
  
    --vp-grid-gutter: 15px;
  }
  
  /* Tablet */
  @media screen and (min-width: 576px) {
    :root {
      /* Font Size */
      --vp-font-size-level-1: calc(calc(42 / 16) * 1rem);
      --vp-font-size-level-2: calc(calc(34 / 16) * 1rem);
  
      /* Spacing */
      --vp-content-spacing: calc(var(--spacing) * 8);
      --vp-content-spacing-V: calc(var(--spacing) * 10);
    }
  }
  
  /* Desktop */
  @media screen and (min-width: 1024px) {
    :root {
      /* Font Size */
      --vp-font-size-level-1: calc(calc(50 / 16) * 1rem);
      --vp-font-size-level-2: calc(calc(42 / 16) * 1rem);
  
      /* Spacing */
      --vp-content-spacing: calc(var(--spacing) * 12);
      --vp-content-spacing-V: calc(var(--spacing) * 12);
  
    }
  }
  
  /* Desktop HD */
  @media screen and (min-width: 1440px) {
    :root {
      --vp-content-spacing: calc(var(--spacing) * 20);
      --vp-content-spacing-V: calc(var(--spacing) * 14);
  
    }
  }