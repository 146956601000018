@charset 'UTF-8';
@import "_setup";

@mixin vp-container($size: "normal") {
  display: grid;
  grid-template-columns: 1fr #{"min(" $vp-container ", 100%)"} 1fr;

  > * {
    grid-column: 2;
  }

  @if $size == "full" {
    grid-template-columns: 0 100% 0;
    padding-left: 0;
    padding-right: 0;
  } @else if $size == "small" {
    grid-template-columns: 1fr #{"min(" $vp-container-small ", 100%)"} 1fr;
  }
}

html {
  scroll-behavior: smooth;
}

.node-type-smartshape,
.page-smartshape {
  #header {
    padding-top: 30px;
    height: auto;

    &::after {
      display: none;
    }

    #header-inner #logo {
      width: 15em;
      overflow: visible;

      @media screen and (min-width: $vp-desktop) {
        width: 25.5em;
      }

      @media screen and (min-width: $vp-large) {
        width: 30em;
      }

      a::before {
        background: $vp-color-white;
        width: 5em;
        left: -19px;
        transform: rotate(-10deg);
        display: none;

        @media screen and (min-width: $vp-desktop) {
          display: block;
          top: 25px;
          height: 15em;
          width: 5em;
          left: -13px;
        }

        @media screen and (min-width: $vp-large) {
          display: block;
          top: 13px;
          height: 19em;
          width: 5em;
          left: -16px;
        }
      }
    }

    &::before {
      width: 100%;
      height: 30px;
      position: absolute;
    }

    #main-menu {
      @include vp-container();
      padding: 0;

      .menu-name-main-menu {
        width: auto;
        max-width: unset;
        padding: 0 var(--vp-content-spacing);
        margin: 0;
        @media screen and (min-width: $vp-desktop) {
        }
      }
    }

    #menu-button {
      position: absolute;
      right: var(--vp-content-spacing);
      top: 0;
      margin-right: 0;
    }

    #main-menu .menu-name-main-menu > ul.menu {
      padding: 100px 0 0;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      background-color: $vp-color-white;
      position: absolute;
      width: 90vw;
      height: 100%;
      max-width: 400px;
      top: -2px;
      right: 0;
      background-color: $vp-color-white;
      min-height: 100vh;
      z-index: 1;

      @media screen and (min-width: $vp-desktop) {
        padding: 0;
        background-color: unset;
        flex-direction: row;
        justify-content: flex-end;
        position: relative;
        top: unset;
        right: unset;
        width: auto;
        max-width: unset;
        height: auto;
        min-height: unset;
      }

      & > li {
        padding-left: 64px;
        width: auto;

        @media screen and (min-width: $vp-desktop) {
          padding-left: 0;

          &:nth-last-child(1) a {
            padding-right: 0;
          }
        }

        & + li {
          @media screen and (min-width: $vp-desktop) {
            margin: 0;
          }
        }

        & > a {
          text-decoration: none;
          text-transform: unset;
          color: $vp-color-primary;
          font-size: 16px;
          font-weight: bold;
          float: unset;

          &:hover {
            color: $vp-color-tertiary;
          }
        }
      }
    }

    #menu-button {
    }
  }

  h1.title {
    font-weight: 400;
    font-size: var(--vp-font-size-level-1);
    text-align: left;
    color: $vp-color-white;
  }

  .componentWrapper {
    max-width: $vp-desktopHD;
    padding: var(--vp-content-spacing-V) var(--vp-content-spacing);
  }

  #banner {
    @include vp-container();

    box-shadow: inset 0 0 0 2000px rgba($vp-color-primary, 0.6);
    height: auto;

    .componentWrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      line-height: 1.35;
      font-weight: bold;
    }

    video {
      position: absolute;
      left: 0;
      top: 0;
      z-index: -1;
      object-fit: cover;
      object-position: center;
      max-width: 100%;
      width: 100%;
      max-height: 100%;
      height: 100%;
    }

    .text {
      max-width: 566px;
      font-size: 20px;
      text-align: left;
      color: $vp-color-white;
    }

    @media screen and (min-width: $vp-desktop) {
      min-height: 632px;
    }
  }

  .t-smartshape {
    overflow: hidden;
    *zoom: 1;
    margin: 0 auto;
    position: relative;
    color: $vp-color-primary;

    .link,
    h2 {
      text-transform: uppercase;
      text-decoration: none;
    }

    h2 {
      font-weight: 400;
      font-size: var(--vp-font-size-level-2);
    }

    ul {
      padding-left: 16px;
      margin: 16px 0;
    }

    .title {
      padding-top: 0;
    }

    .btn {
      margin-top: 16px;
      text-decoration: none;

      div {
        text-transform: uppercase;
        font-size: 19px;
        font-weight: bold;
        background-color: $vp-color-tertiary;
        padding: 16px 24px 16px 48px;
        color: $vp-color-white;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          left: 16px;
          top: calc(50% - 7px);
          width: 18px;
          height: 15px;
          background: url(../../images/left-arrow.svg) center no-repeat;
          background-size: contain;
        }
      }
    }

    /*  Intro */

    .m-intro {
      @include vp-container(small);

      .title {
        text-align: center;
        margin-bottom: 8px;
      }

      .text {
        text-align: center;
        font-size: 19px;
        font-weight: 400;
        line-height: 1.5;
      }
    }

    /*  Image */

    .m-image {
      @include vp-container();

      .componentWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .image {
        width: 100%;
        max-width: 920px;

        img {
          width: 100%;
        }
      }

      @media screen and (min-width: $vp-desktop) {
      }
    }

    /*  Text Image */

    .m-textImage {
      @include vp-container();

      background-color: $vp-color-grey;
    }

    .m-textImage .componentWrapper {
      display: flex;
      flex-direction: column-reverse;
      justify-content: flex-start;
      align-items: flex-start;

      .text {
        @media screen and (min-width: $vp-desktop) {
          max-width: 540px;
        }
      }
    }

    .m-textImage .image {
      margin-bottom: 32px;
      width: 100%;
      max-width: 100%;
    }

    .m-textImage .image img {
      width: 100%;
    }

    #process {
      .image {
        max-width: 100%;
        max-height: 400px;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    @media screen and (min-width: $vp-desktop) {
      .m-textImage .componentWrapper {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
      }

      .m-textImage.--reverse .componentWrapper {
        flex-direction: row-reverse;
      }

      .m-textImage .image {
        margin-bottom: 0;
        max-width: 445px;
      }

      #process {
        .image {
          margin-bottom: 0;
          max-width: 445px;
          max-height: 300px;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .m-textImage .link {
      color: $vp-color-tertiary;
      font-size: 20px;
      position: relative;
      padding-left: 28px;
      font-weight: bold;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: calc(50% - 7px);
        width: 18px;
        height: 15px;
        background: url(../../images/left-arrow-tertiary.svg) center no-repeat;
        background-size: contain;
      }
    }

    .m-textImage.--backgroundPrimary {
      background-color: $vp-color-primary;
      color: $vp-color-white;

      .link {
        color: $vp-color-secondary-alt;
        font-weight: bold;

        &::before {
          background: url(../../images/left-arrow-secondary-alt.svg) center
            no-repeat;
        }
      }
    }

    /* Benefits */

    /* Card */

    .m-card {
      @include vp-container();

      .componentWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    .m-card .cardList {
      //   display: grid;
      //   justify-content: space-between;
      //   grid-template-columns: repeat(1, 1fr);
      //   grid-gap: 32px 32px;

      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;

      @media screen and (min-width: $vp-desktop) {
        margin-bottom: 80px;
        border: 5px solid $vp-color-secondary;
        border-radius: 50%;
        width: 380px;
        height: 380px;
      }

      .logo {
        width: 190px;
        min-width: 190px;
        min-height: 144px;
        margin-bottom: 32px;

        @media screen and (min-width: $vp-desktop) {
          margin-bottom: 0;
        }

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .m-card .cardItem {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      @media screen and (min-width: $vp-desktop) {
        position: absolute;
        width: max-content;
        margin-bottom: 0;
      }

      .icon {
        margin-right: 24px;
        border: 2px solid $vp-color-secondary;
        border-radius: 50%;
        overflow: hidden;
        width: 80px;
        height: 80px;
        box-shadow: 2px 2px 4px 0 rgba($vp-color-primary, 0.2);
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $vp-color-white;

        @media screen and (min-width: $vp-desktop) {
          width: 108px;
          height: 108px;
        }

        svg {
          width: 50%;
          height: 50%;
        }
      }

      .text {
        box-shadow: 0 5px 5px 0 rgba(2, 167, 225, 0.2);
        background-color: $vp-color-white;
        padding: 16px;

        p {
          padding-top: 0;
        }

        ul {
          margin: 0;
          padding: 0 0 0 16px;
        }
      }

      &.--p1 {
        top: 0;
        left: -80%;
      }

      &.--p2 {
        top: -10%;
        right: -70%;
      }

      &.--p3 {
        top: 40%;
        right: -90%;
      }

      &.--p4 {
        bottom: -30%;
        left: 43%;

        .text {
          @media screen and (min-width: $vp-desktop) {
            margin-top: 120px;
          }
        }
      }

      &.--p5 {
        left: -90%;
        top: 50%;
      }

      &.--left {
        @media screen and (min-width: $vp-desktop) {
          flex-direction: row-reverse;
        }

        .icon {
          @media screen and (min-width: $vp-desktop) {
            margin-right: 0;
            margin-left: 24px;
          }
        }
      }
    }

    .m-card .title,
    .m-card .desc {
      text-align: center;
    }

    .m-card .desc {
      font-size: 19px;
      max-width: 726px;
      margin: 0 auto;
      margin-bottom: 16px;
    }

    .btn {
      margin-top: 0;
      margin-bottom: 72px;
      position: relative;
    }

    .cta {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .text  {
        font-size: 19px;
        font-weight: 400;
      }
    }

    @media screen and (min-width: $vp-tablet) {
      .m-card .cardList {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    @media screen and (min-width: $vp-desktop) {
      .m-card .cardList {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    /* Table */

    .m-table {
      background-color: $vp-color-grey;
      @include vp-container();
    }

    .m-table .componentWrapper {
      max-width: 920px;
      margin: 0 auto;
    }

    .m-table .title {
      text-align: center;
    }

    .m-table .underline {
      text-align: center;
      margin-bottom: 16px;
      font-weight: bold;
      font-size: 20px;
    }

    .m-table .desc {
      text-align: left;
      margin-bottom: 16px;
      font-weight: bold;
      font-size: 20px;
    }

    .m-table .list {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .m-table .listItem {
      width: 100%;
      font-size: 19px;
      padding: 16px;
      font-weight: 400;
    }

    .m-table .listItem:nth-child(2n-1) {
      background-color: $vp-color-white;
    }

    @media screen and (min-width: $vp-tablet) {
      .m-table .list {
      }

      .m-table .listItem {
        width: 44%;
      }

      .m-table .listItem:nth-child(2n-1) {
        background-color: unset;
      }

      .m-table .listItem:nth-child(4n-2),
      .m-table .listItem:nth-child(4n-3) {
        background-color: $vp-color-white;
      }
    }
  }

  /* Grid */

  .o-grid {
    @include vp-container();

    .componentWrapper {
    }

    .desc {
      font-size: 20px;
      color: $vp-color-primary;
      font-weight: 700;
      margin-bottom: 32px;
    }

    .grid {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 30px;

      @media screen and (min-width: $vp-tablet) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media screen and (min-width: $vp-desktop) {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    .firstCol,
    .secondCol,
    .thirdCol {
      .grid-item {
        & > img {
          max-width: 100%;
          width: 100%;
        }

        & + .grid-item {
          margin-top: 12px;
        }
      }

      .logo {
        padding: 16px 12px;
        background-color: $vp-color-primary;
        max-height: 60px;
        min-height: 60px;
        display: flex;
        align-items: center;

        img {
          max-height: inherit;
        }
      }

      .list {
        margin: 0;
        padding: 16px 12px;
        box-shadow: 0 2px 4px 0 rgba($vp-color-dark, 0.3);
      }

      .item {
        list-style: none;
        font-size: 19px;
        color: $vp-color-primary;
        font-weight: 400;
      }
    }
  }

  /*
  * Contact form
  */
  .o-contact {
    @include vp-container();

    .title {
      text-align: center;
    }

    .desc {
      text-align: center;
      max-width: 726px;
      font-size: 19px;
      font-weight: 400;
      margin: 0 auto 40px;
    }

    form > div {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .field {
      margin-bottom: 10px;
      width: 100%;

      label {
        text-transform: none;
        font-size: 19px;
      }

      &.--half {
        width: 100%;

        @media screen and (min-width: $vp-tablet) {
          width: 45%;
        }

        @media screen and (min-width: $vp-desktop) {
          width: 47%;
        }
      }

      input,
      textarea {
        width: -webkit-fill-available;
        width: -moz-available;
        background: none;
        height: auto;
        padding: 10px 16px;
        border: 1px solid rgba($vp-color-dark, 0.4);
        color: $vp-color-primary;
        font-size: 19px;
      }

      textarea {
        width: -webkit-fill-available;
        width: -moz-available;
      }
    }

    .rgpd {
      height: fit-content;

      .form-checkboxes {
        .form-item {
          display: flex;

          input  {
          }

          label {
            margin-left: 16px;
            text-transform: none;
          }
        }
      }

      label[for="edit-submitted-rgpd-agreement"] {
        display: none;
      }
    }

    .form-actions {
      width: 100%;

      input {
        float: left;
      }
    }
  }

  /*
  * FOOTER
  */

  .o-footer {
    background-color: $vp-color-primary;
    @include vp-container();
    width: 100%;
    height: auto;

    .componentWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 48px var(--vp-content-spacing);
      flex-direction: column;

      @media screen and (min-width: $vp-tablet) {
        padding: 20px var(--vp-content-spacing);

        flex-direction: row;
      }
      .link {
        font-size: 14px;
        color: $vp-color-grey-dark;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      .industriesLinks {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        flex-direction: column;
        margin-bottom: 32px;

        @media screen and (min-width: $vp-tablet) {
          flex-direction: row;
          margin-bottom: 0px;
        }

        .logo {
          width: 113px;
          height: 56px;
          margin-bottom: 32px;

          @media screen and (min-width: $vp-tablet) {
            margin-bottom: 0px;
          }
          a,
          img {
            width: 100%;
            height: 100%;
          }
        }

        .link {
          @media screen and (min-width: $vp-tablet) {
            margin-left: 61px;
          }
        }
      }

      .smartshapeLinks {
        display: flex;
        align-items: center;

        .link {
          &.link {
            padding-left: 16px;
          }
        }
      }
    }
  }
}
